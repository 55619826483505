<template>
  <page-content>

    <common-table
      ref="table"
      path="web/mall/goods"
      :query-params="queryParams"
      :columns="columns">

      <template slot="search">
        <a-button type="primary" ghost @click="add">发布商品</a-button>

        <a-radio-group v-model="queryParams.enable" style="margin-left: 30px" @change="getData">
          <a-radio-button :value="true">已上架</a-radio-button>
          <a-radio-button :value="false">已下架</a-radio-button>
        </a-radio-group>

      </template>

      <template slot="picture" slot-scope="{record}">
        <a-avatar :size="60" shape="square"  :src="record.picture"/>
      </template>

      <template slot="enable" slot-scope="{record}">
        <a-switch :checked="record.enable" size="small" @click="changeEnable(record)"></a-switch>
      </template>


      <template slot="operation" slot-scope="{record}">
        <action-edit @click="edit(record)"></action-edit>
        <a-button size="small" @click="clickSort(record)">排序</a-button>
<!--        <action-delete @click="remove(record)"></action-delete>-->
      </template>

    </common-table>

    <input-dialog ref="inputDialog"></input-dialog>

  </page-content>
</template>

<script>

  export default {
    data() {
      return {
        queryParams:{
          enable:true
        }
      }
    },
    computed: {
      columns() {
        return [
          {
            title: '商品ID',
            dataIndex: 'id',
          },
           {
            title: '商品图片',
            dataIndex: 'picture',
            scopedSlots: {customRender: 'picture'}
          },
          {
            title: '商品名称',
            dataIndex: 'name',
          },
          {
            title: '商品编码',
            dataIndex: 'sn',
          },
          {
            title: '排序',
            dataIndex: 'sort',
          },
          {
            title: '售价',
            dataIndex: 'price',
            customRender(text){
              return "￥"+ text/100
            }
          },
          {
            title: '上下架',
            dataIndex: 'enable',
            scopedSlots: {customRender: 'enable'},
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
          }]
      }
    },

    methods: {
      clickSort(record){
        let self = this
        this.$refs.inputDialog.show({
          title:"修改排序",
          value:record.sort,
          placeholder:"",
          onOk(value){
            self.$put(`web/mall/goods/spu`,{
              id:record.id,
              sort:parseInt(value)
            }).then(suc=>{
              self.getData()
            })
          }
        })
      },
      onEnableChanged(){
        this.getData()
      },

      changeEnable(record){
        this.$put(`web/mall/goods/spu`,{
          id:record.id,
          enable:!record.enable
        }).then(suc=>{
          this.getData()
        })
      },

      add() {
        this.$router.replace("/mall/goodsAdd")
      },
      edit(record) {
        this.$router.replace("/mall/goodsAdd?id="+record.id)
      },
      getData() {
        this.$refs.table.getData()
      },

      remove(record) {
        let that = this
        this.$confirm({
          title: '确定删除 ' + record.text,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            that.$delete('menu/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getData()
            })
          }
        })
      },

    }
  }
</script>
<style lang="less" scoped>

</style>
